// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-fotogallerie-js": () => import("./../../../src/pages/de/fotogallerie.js" /* webpackChunkName: "component---src-pages-de-fotogallerie-js" */),
  "component---src-pages-de-gift-registry-js": () => import("./../../../src/pages/de/gift-registry.js" /* webpackChunkName: "component---src-pages-de-gift-registry-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-reserve-js": () => import("./../../../src/pages/de/reserve.js" /* webpackChunkName: "component---src-pages-de-reserve-js" */),
  "component---src-pages-de-teilnahme-bestatigen-js": () => import("./../../../src/pages/de/teilnahme-bestatigen.js" /* webpackChunkName: "component---src-pages-de-teilnahme-bestatigen-js" */),
  "component---src-pages-de-unterkunft-js": () => import("./../../../src/pages/de/unterkunft.js" /* webpackChunkName: "component---src-pages-de-unterkunft-js" */),
  "component---src-pages-en-accommodation-js": () => import("./../../../src/pages/en/accommodation.js" /* webpackChunkName: "component---src-pages-en-accommodation-js" */),
  "component---src-pages-en-gift-registry-js": () => import("./../../../src/pages/en/gift-registry.js" /* webpackChunkName: "component---src-pages-en-gift-registry-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-photogallery-js": () => import("./../../../src/pages/en/photogallery.js" /* webpackChunkName: "component---src-pages-en-photogallery-js" */),
  "component---src-pages-en-reserve-js": () => import("./../../../src/pages/en/reserve.js" /* webpackChunkName: "component---src-pages-en-reserve-js" */),
  "component---src-pages-en-rsvp-js": () => import("./../../../src/pages/en/rsvp.js" /* webpackChunkName: "component---src-pages-en-rsvp-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-hr-accommodation-js": () => import("./../../../src/pages/hr/accommodation.js" /* webpackChunkName: "component---src-pages-hr-accommodation-js" */),
  "component---src-pages-hr-hochzeitsgeschenke-js": () => import("./../../../src/pages/hr/hochzeitsgeschenke.js" /* webpackChunkName: "component---src-pages-hr-hochzeitsgeschenke-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-photogallery-js": () => import("./../../../src/pages/hr/photogallery.js" /* webpackChunkName: "component---src-pages-hr-photogallery-js" */),
  "component---src-pages-hr-reservieren-js": () => import("./../../../src/pages/hr/reservieren.js" /* webpackChunkName: "component---src-pages-hr-reservieren-js" */),
  "component---src-pages-hr-rsvp-js": () => import("./../../../src/pages/hr/rsvp.js" /* webpackChunkName: "component---src-pages-hr-rsvp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-galeria-zdjec-js": () => import("./../../../src/pages/pl/galeria-zdjec.js" /* webpackChunkName: "component---src-pages-pl-galeria-zdjec-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-potwierdz-udzial-js": () => import("./../../../src/pages/pl/potwierdz-udział.js" /* webpackChunkName: "component---src-pages-pl-potwierdz-udzial-js" */),
  "component---src-pages-pl-rejestr-podarunkowy-js": () => import("./../../../src/pages/pl/rejestr-podarunkowy.js" /* webpackChunkName: "component---src-pages-pl-rejestr-podarunkowy-js" */),
  "component---src-pages-pl-zakwaterowanie-js": () => import("./../../../src/pages/pl/zakwaterowanie.js" /* webpackChunkName: "component---src-pages-pl-zakwaterowanie-js" */),
  "component---src-pages-pl-zarezerwuj-prezent-js": () => import("./../../../src/pages/pl/zarezerwuj-prezent.js" /* webpackChunkName: "component---src-pages-pl-zarezerwuj-prezent-js" */),
  "component---src-pages-potvrdit-ucast-js": () => import("./../../../src/pages/potvrdit-ucast.js" /* webpackChunkName: "component---src-pages-potvrdit-ucast-js" */),
  "component---src-pages-rezervovat-js": () => import("./../../../src/pages/rezervovat.js" /* webpackChunkName: "component---src-pages-rezervovat-js" */),
  "component---src-pages-svatebni-dary-js": () => import("./../../../src/pages/svatebni-dary.js" /* webpackChunkName: "component---src-pages-svatebni-dary-js" */),
  "component---src-pages-ubytovani-js": () => import("./../../../src/pages/ubytovani.js" /* webpackChunkName: "component---src-pages-ubytovani-js" */)
}

